@import "colours";

.console-green {
  color: $green;
}

.console-yellow {
  color: $yellow;
}

.console-bg-yellow {
  background: $yellow;
  color: black;
}

span[class^="console-"].bold {
  font-weight: bold;
}

.console-red {
  color: $red;

  span[class^="hljs-"] {
    color: $red;
  }
}

.console-blue {
  color: $blue;

  span[class^="hljs-"] {
    color: $blue;
  }
}

.console-grey {
  color: $grey;

  span[class^="hljs-"] {
    color: $grey;
  }
}

.unused-clause {
  opacity: 0.5;
}

.unfocussed {
  opacity: 0.66;
}

.hljs-number {
  color: $yellow;
}

.console-bg-red {
  background: $red;
  color: black;
}
