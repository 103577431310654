@import "colours";

html {
  height: 100%;
}

body {
  height: 100%;

  font-family: "Bitter";
  -webkit-text-size-adjust: 100%;
  @media screen {
    background: linear-gradient(
      145deg,
      $lighter-purple,
      darken($lighter-purple, 25)
    );
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  margin: 0;
}

h1,
h2,
h3,
h4 {
  color: $purple;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

h3 {
  font-size: 1.25rem;
}

p,
ul,
ol {
  line-height: 150%;
}

a {
  color: $light-purple;
}

article {
  img {
    max-width: 100%;
  }

  code {
    font-family: "Anonymous Pro";
  }

  p,
  ul {
    code,
    kbd {
      background: #fbecff;
      border-radius: 3px;
      padding: 3px;
    }
  }

  pre {
    code {
      display: block;
      background: black;
      color: white;
      line-height: 133%;
      border-left: 5px solid $light-purple;
      border-radius: 5px;

      &.custom {
        padding: 0.5em;
      }

      font-size: 80%;

      @media only screen and (min-device-width: 750px) {
        font-size: 100%;
      }
    }
  }

  aside {
    border-color: #80ded9;
    background: lighten(#80ded9, 30);

    header {
      font-weight: bold;
      font-size: 1.5rem;
    }
  }

  aside + aside {
    margin-top: 20px;
  }

  aside,
  blockquote {
    border-radius: 5px;
    padding: 15px;
    border-left-style: solid;
    border-left-width: 5px;

    p:last-child {
      margin-bottom: 0px;
    }
  }

  blockquote {
    margin: 0;
    font-size: 95%;
    border-left: 5px solid #9dd1f1;
    background: lighten(#9dd1f1, 15);

    p:first-child {
      margin: 0;
    }
  }

  figure {
    text-align: center;

    img {
      max-width: 45vw;
      border: 1px solid #d7d7d7;
    }

    figcaption {
      font-size: 85%;
    }
  }
}

footer {
  text-align: center;
}

.wrapper {
  @media only screen and (min-width: 700px) {
    margin: 0 5%;
    padding: 2.5% 5%;
  }

  border-left: 3px solid $lighter-purple;
  border-right: 3px solid $light-purple;

  background: white;
  padding: 2.5% 5%;
}

.toc {
  font-size: 1.2rem;
  line-height: 150%;
}

.center {
  text-align: center;
}

.todo {
  background: #de0000;
  padding: 25px;
  color: white;
  font-weight: bold;
}

.todo + .todo {
  margin-top: 25px;
}

.large {
  font-size: 2.5rem;
}

.intro {
  .cover {
    text-align: center;
  }
  .cover img {
    max-width: 100%;
    max-height: 200px;
    @media only screen and (min-width: 48em) {
      max-height: 500px;
    }
  }
  .buy {
    font-family: "Bitter";
    border-radius: 3px;
    border: 3px solid darken($purple, 5);
    font-size: 150%;
    padding: 10px 20px;
    color: white;
    background: linear-gradient(125deg, $purple, lighten($purple, 25));
  }
}

.highlight {
  padding: 2px 3px;
}

.highlight-red {
  background: $red;
  color: lighten($red, 30);
}

.highlight-yellow {
  background: $yellow;
  color: lighten($yellow, 30);
}

.highlight-green {
  background: $green;
  color: lighten($green, 30);
}

.highlight-blue {
  background: $blue;
  color: lighten($blue, 25);
}

.autocomplete-suggestions {
  border-left: 5px solid $light-purple;
  background: black;
  color: white;
  font-family: "Anonymous Pro";

  td {
    padding: 0.5em;
  }
}

.pagination {
  margin-bottom: 3rem;
  a {
    background: lighten($lighter-purple, 10);
    text-decoration: none;
    border: 1px solid darken($lighter-purple, 10);
    color: $purple;
    font-weight: 600;
    padding: 10px;
    border-radius: 5px;
  }

  a + a {
    margin-left: 10px;
  }
}
